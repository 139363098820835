import axios, { AxiosResponse } from 'axios'
import { debug } from 'debug'

import { generateErrorTemplate, getCustomerName, storage } from '@olyslager/global-utilities'

import { CUSTOMER_HEADER_NAME, LOCALSTORAGE_TOKEN_KEY } from '../constants'

const debugRequest = debug('api-client:authClient:request')
const debugGetAccessToken = debug('api-client:authClient:getAccessToken')
const debugResponse = debug('api-client:authClient:response')
const debugRefresh = debug('api-client:authClient:refreshToken')

const AUTH_URL = '/authentication/token'
const TOKEN_LOGIN_ENDPOINT = '/authentication/tokenlogin'

const client = axios.create({
  baseURL: API_URL_AUTH
})

client.interceptors.request.use(
  async config => {
    debugRequest('Requesting %s', config.url)
    config.headers['x-oly-subscription'] = API_MANAGEMENT_SUBSCRIPTION_KEY || ''
    return config
  },
  error => {
    Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => {
    debugResponse(`Request response from ${response.config.url} %O`, response)
    return response
  },
  async function (error) {
    debugResponse(`Request error from ${error.config.url} %O`, error)
    if (!error.response) {
      debugResponse('Error without response object')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export function getAccessToken (): string | null {
  debugGetAccessToken('Reading access token')
  const accessToken = storage.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
  if (accessToken === null) {
    return null
  }
  return `Bearer ${accessToken}`
}

export async function refreshAccessToken (customer: string) : Promise<void> {
  try {
    debugRefresh('Refreshing access token')
    const response = await client.get(AUTH_URL, {
      headers: {
        [CUSTOMER_HEADER_NAME]: customer
      }
    })
    storeAccessToken(response.data)
    debugRefresh('Refreshed access token')
  } catch (error: unknown) {
    debugRefresh('Refreshing access token failed', error)
    const main = document.querySelector('body>main')
    if (main) {
      main.innerHTML = generateErrorTemplate(new Error('Client error'))
    }
  }
}

function storeAccessToken (accessToken: string) : void {
  storage.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, accessToken)
}

export async function getAccessTokenWithCredentials (username: string, password: string): Promise<AxiosResponse<string>> {
  const response: AxiosResponse<string> = await client.post(TOKEN_LOGIN_ENDPOINT, {
    Username: username,
    Password: password
  }, {
    headers: {
      [CUSTOMER_HEADER_NAME]: getCustomerName()
    }
  })

  return response
}
