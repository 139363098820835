import { datasets } from '@olyslager/global-utilities'

import { makeTypePolicies } from '../factory/apollo/makeTypePolicies'
import { clientFactory } from '../factory/clientFactoryApollo'

const client = clientFactory.createClient({
  apiUrl: API_URL_DATA,
  typePolicies: {
    ...makeTypePolicies('Category', ['categoryName']),
    ...makeTypePolicies('Make', ['makeName']),
    ...makeTypePolicies('Model', ['modelName']),
    ...makeTypePolicies('Type', ['typeName', 'driveType', 'engineBuild', 'fuel']),
    ...makeTypePolicies('Component', ['componentName']),
    ...makeTypePolicies('Recommendation', ['productName', 'productMsds', 'productPds', 'productUrl', 'temperatureName', 'useName']),
    ...makeTypePolicies('Interval', ['intervalName', 'intervalType']),
    ...makeTypePolicies('Capacity', ['condition', 'item', 'text'])
  },
  additionalHeadersFunc () {
    return getDatasetHeader()
  }
})

function getDatasetHeader () : Record<string, string> {
  const currentDataset = datasets.getCurrentDataset()
  if (currentDataset) {
    return { 'x-oly-dataset': currentDataset.code }
  }
  return { }
}

export const dataClient = {
  get: client.executeQuery
}
