import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { debug } from 'debug'

import { getAccessToken, refreshAccessToken } from '../clients/authClient'
import { CUSTOMER_HEADER_NAME } from '../constants'
const debugRequest = debug('api-client:baseClient:request')
const debugResponse = debug('api-client:baseClient:response')

function createClient (config?: AxiosRequestConfig) {
  const client = axios.create(config)
  return client
}

function addDefaultRequestInterceptor (client: AxiosInstance) {
  client.interceptors.request.use(
    async config => {
      debugRequest('Requesting %s', config.url)

      let accessToken = getAccessToken()
      if (accessToken === null) {
        debugRequest('No access token found, getting a new one')
        await refreshAccessToken(config.headers[CUSTOMER_HEADER_NAME])
        accessToken = getAccessToken()
      }
      config.headers.Authorization = accessToken
      config.headers['x-oly-subscription'] = API_MANAGEMENT_SUBSCRIPTION_KEY || ''

      return config
    },
    error => {
      Promise.reject(error)
    }
  )
}

function addDefaultResponseInterceptor (client: AxiosInstance) {
  client.interceptors.response.use(
    (response) => {
      debugResponse(`Request response from ${response.config.url} %O`, response)
      return response
    },
    async function (error) {
      debugResponse(`Request error from ${error.config.url} %O`, error)
      const originalRequest = error.config
      if (!error.response) {
        debugResponse('Error without response object')
        return Promise.reject(error)
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        debugResponse('401 Unauthorized, getting new token')
        originalRequest._retry = true
        await refreshAccessToken(error.config.headers[CUSTOMER_HEADER_NAME])
        return client(originalRequest)
      }
      return Promise.reject(error)
    }
  )
}

export const clientFactory = {
  createClient,
  addDefaultRequestInterceptor,
  addDefaultResponseInterceptor
}
