import { TypePolicies } from '@apollo/client/cache/inmemory/policies'
import { ApolloClient, ApolloError, ApolloLink, ApolloQueryResult, DocumentNode, FetchResult, InMemoryCache, NormalizedCacheObject, OperationVariables, ServerError, createHttpLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { debug } from 'debug'
import { jwtDecode } from 'jwt-decode'

import { getCustomerName } from '@olyslager/global-utilities'

import { getAccessToken, refreshAccessToken } from '../clients/authClient'
import { CUSTOMER_HEADER_NAME } from '../constants'

const debugGetData = debug('api-client:dataClient:getData')

export declare type QueryError = {
  message: string;
  code?: string | undefined;
}

export declare type QueryResult<T> = {
  data?: T | null | undefined;
  errors?: QueryError[] | undefined;
  statusCode: number | undefined;
}

export declare type QueryDocument = DocumentNode
export declare type Variables = OperationVariables

export declare interface CreateClientOptions {
  apiUrl: string,
  disableTypeNames?: boolean,
  typePolicies?: TypePolicies,
  additionalHeaders?: Record<string, string>,
  additionalHeadersFunc?: () => Record<string, string>
}

function createClient (options: CreateClientOptions) {
  const { apiUrl, disableTypeNames, typePolicies } = options
  // Create the authLink
  const authLink = setContext(async (_, { headers }) => {
    // Get the access token
    let accessToken = getAccessToken()
    if (accessToken === null) {
      debugGetData('No access token found, getting a new one')
      await refreshAccessToken(getCustomerName())
      accessToken = getAccessToken()
      if (accessToken === null) {
        debugGetData('Failed to get access token')
        throw new Error('Failed to get access token')
      }
    } else {
      // Read access token and check for expiration
      const decodedJwt = jwtDecode(accessToken)

      if (!decodedJwt.exp) {
        debugGetData('Failed to get access token')
        throw new Error('Failed to get access token')
      }

      // shorten expiration by 5 minutes to account for network delays
      const expShortened = decodedJwt.exp - (60 * 5)

      if (Date.now() >= expShortened * 1000) {
        debugGetData('Access token expired, getting a new one')
        await refreshAccessToken(getCustomerName())
        accessToken = getAccessToken()
        if (accessToken === null) {
          debugGetData('Failed to get access token')
          throw new Error('Failed to get access token')
        }
      }
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: accessToken || '',
        'x-oly-subscription': API_MANAGEMENT_SUBSCRIPTION_KEY || '',
        ...options.additionalHeaders,
        ...options.additionalHeadersFunc?.()
      }
    }
  })

  // Create the httpLink
  const httpLink: ApolloLink = createHttpLink({
    uri: apiUrl,
    headers: {
      [CUSTOMER_HEADER_NAME]: getCustomerName()
    }
  })

  // Create the client
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: !disableTypeNames,
      typePolicies
    })
  })

  async function executeQuery<T> (document: QueryDocument, variables?: OperationVariables) : Promise<QueryResult<T>> {
    debugGetData('Executing query')
    try {
      // Execute query with the default fetchPolicy 'cache-first'
      const queryResult: ApolloQueryResult<T> = await client.query<T>({
        query: document,
        variables,
        errorPolicy: 'all'
      })
      debugGetData('Query Results', queryResult)

      const response = {
        data: queryResult.data,
        statusCode: 200,
        errors: (queryResult.errors?.length)
          ? [...queryResult.errors.map(e => {
              return {
                code: e.extensions?.code,
                message: e.message
              } as QueryError
            })]
          : undefined
      }

      debugGetData('Query Return value', response)

      return response
    } catch (error: unknown) {
      const err = error as ApolloError
      const networkError = err?.networkError as ServerError

      const response = {
        data: undefined,
        statusCode: networkError?.statusCode,
        // @ts-expect-error errors exist in result
        errors: networkError?.result?.errors?.length
          // @ts-expect-error errors exist in result
          ? [...networkError.result.errors.map(e => {
              return {
                code: e.extensions?.code,
                message: e.message
              } as QueryError
            })]
          : [error]
      }

      debugGetData('Query Error value', response)

      return response
    }
  }

  async function executeMutation<T> (document: QueryDocument, variables?: OperationVariables) : Promise<QueryResult<T>> {
    debugGetData('Executing mutation')
    try {
      const queryResult: FetchResult<T> = await client.mutate<T>({
        mutation: document,
        variables
      })
      debugGetData('Mutation Results', queryResult)

      const response = {
        data: queryResult.data,
        statusCode: 200,
        errors: (queryResult.errors?.length)
          ? [...queryResult.errors.map(e => {
              return {
                code: e.extensions?.code,
                message: e.message
              } as QueryError
            })]
          : undefined
      }

      debugGetData('Query Return value', response)

      return response
    } catch (error: unknown) {
      const err = error as ApolloError
      const networkError = err?.networkError as ServerError

      const response = {
        data: undefined,
        statusCode: networkError?.statusCode,
        // @ts-expect-error errors exist in result
        errors: networkError?.result?.errors?.length
          // @ts-expect-error errors exist in result
          ? [...networkError.result.errors.map(e => {
              return {
                code: e.extensions?.code,
                message: e.message
              } as QueryError
            })]
          : [error]
      }

      debugGetData('Query Error value', response)

      return response
    }
  }

  return {
    executeQuery,
    executeMutation
  }
}

export const clientFactory = {
  createClient
}
