import { clientFactory } from '../factory/clientFactoryAxios'

const client = clientFactory.createClient({
  baseURL: API_URL_UTIL + '/api/'
})

clientFactory.addDefaultRequestInterceptor(client)
clientFactory.addDefaultResponseInterceptor(client)

export const utilClient = {
  get: client.get,
  post: client.post
}
