import { clientFactory } from '../factory/clientFactoryApollo'

const client = clientFactory.createClient({
  apiUrl: API_URL_CONFIG,
  disableTypeNames: true
})

export const configClientGraphQL = {
  get: client.executeQuery,
  mutate: client.executeMutation
}
