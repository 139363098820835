import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

export function makeTypePolicies (typeName: string, fieldNames: string[]) {
  const fields = {}

  const keyArgs: KeyArgsFunction = (_, context) => {
    return context.variables?.language
  }

  for (const fieldName of fieldNames) {
    fields[fieldName] = {
      keyArgs
    }
  }

  const typePolicy = {
    [typeName]: {
      fields
    }
  }

  return typePolicy
}
